@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Poppins:wght@700&display=swap');

@font-face {
  font-family: "Proxima Nova Regular";
  src: url("../../fonts/ProximaNova-Regular.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("../../fonts/Proxima-Nova-Semibold.woff") format("woff");
}
@font-face {
  font-family: "Proxima Nova Extrabold";
  src: url("../../fonts/Proxima-Nova-Extrabold.woff") format("woff");
}

$principal: #1a3a87;
$secondary: #12a6de;

html, body{
  margin: 0;
  padding: 0;
  color: #ccc;
  font-family: "Proxima Nova Extrabold";
  background: #1a3a87;
}
a, a:hover{
  text-decoration: none;
}
#root{
  // background: #111418;
  width: 100%;
  overflow: hidden;
  display: block;
  // min-height: 100vh;
}
// .page{
//   background: #111418;
// }
.pad{
  padding: 6px;
}
.hover-t{
  a{
    display: block;
    position: relative;
  }
  a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after { 
    width: 100%; 
    left: 0; 
  }  
}


@media only screen and (min-width: 2160px) {
  @import 'mupi.scss';
}
@media only all and (min-width: 1024px) and (max-width: 2159px) {
  @import 'desktop.scss';
}
// mobile
@media only all and (min-width: 415px) and (max-width: 1023px) {
  @import 'tablet.scss';
}
@media only all and (max-width: 414px) {
  @import 'mobile.scss';
}

.border-tags{
  padding: 10px;
  margin-top: 10px;
  color: white;
}
.border-capsulas{
  border-top: 1px solid rgb(157,53,80);            
}
.border-en-serio{
  border-top: 1px solid rgb(218,82,135);
}
.border-facts{
  border-top: 1px solid rgb(42 ,27,60);
}
.border-hobbies{
  border-top: 1px solid rgb(69,57,141);
}         
.border-memes{
  border-top: 1px solid rgb(130,101,167);            
}     
.border-tech{
  border-top: 1px solid rgb(84,129,232);            
}             
.border-travel{
  border-top: 1px solid rgb(164,179,182);            
}   

// tags
.capsulas{
    background-color: rgba(157,53,80, 1);            
}
.en-serio{
    background-color: rgba(218,82,135, 1);
}
.facts{
    background-color: rgba(42   ,27,60, 1);
}
.hobbies{
    background-color: rgba(69,57,141, 1);
}         
.memes{
    background-color: rgba(130,101,167, 1);            
}     
.tech{
    background-color: rgba(84,129,232, 1);            
}             
.travel{
    background-color: rgba(164,179,182, 1);            
}   
