html, body{
    font-size: 3.2vw;
}
.content{
    overflow: hidden;
    background-size: 100%;
    background-attachment: fixed;
    // min-height: 100vh;

    .left{
        width: 20%;
        top: 0;
        left: -100%;
        height: 100vh;
        position: fixed;
        margin-right: 1%;
        background-color: white;
        .left-menu {
            padding-top: 90px;
            ul{
                margin: 0;
                padding: 0;

                li{
                    list-style: none;
                    transition: all 0.3s ease-in-out;  
                    // padding: 10px 20px;
                    a{
                        display: block;
                        padding: 20px;
                        font-size: 20px;
                        color: unset;
                    }
                }
                // li:hover{
                //     a{
                //         color: #000
                //     }
                // }
                li:nth-child(1):hover{
                    a:after{
                        background-color: rgba(157,53,80, 1);            
                    }
                }
                li:nth-child(2):hover, li:nth-child(8):hover{
                    a:after{
                        background-color: rgba(218,82,135, 1);
                    }
                }
                li:nth-child(3):hover, li:nth-child(9):hover{
                    a:after{
                        background-color: rgba(42   ,27,60, 1);
                    }
                }
                li:nth-child(4):hover, li:nth-child(10):hover{
                    a:after{
                        background-color: rgba(69,57,141, 1);
                    }
                }         
                li:nth-child(5):hover, li:nth-child(11):hover{
                    a:after{
                        background-color: rgba(130,101,167, 1);            
                    }
                }     
                li:nth-child(6):hover, li:nth-child(12):hover{
                    a:after{
                        background-color: rgba(84,129,232, 1);            
                    }
                }             
                li:nth-child(7):hover, li:nth-child(13):hover{
                    a:after{
                        background-color: rgba(164,179,182, 1);            
                    }
                }                           
            }
        }
    }
    .active{
        left: 0;
        z-index: 3;        
    }
    .center{
        width: 100%;
        // background: rgba(204, 220, 243, 0.5);
        // min-height: 100vh;
        .App{
            // padding-top: 90px;
            // background: rgba(0,0,0,0.8);
            // min-height: 100vh;
            padding-bottom: 10px;
        }
    }
}
// .menuButton, .hideMenu{
//     display: none !important;
// }
.header{
    background-color: white;
}
.posts{
    width: 100%;
    margin: 0 auto;
    min-height: 100%;    
}
.buscador{
    .keyboard{
        border-top: 35px solid white;
        background-color: #1a3a87;
        display: block;
        overflow: visible;
        padding: 40px 0 10px;
        z-index: 1;
    }
    h2 {
        background-color: #12a6de;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        text-align: center;
        width: 94%;
        margin: -60px auto 0;
        z-index: 2;
        position: relative;
        padding: 6px;
    } 
    h3{
        text-align: center;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 3em;
    }    
    .bottom{
        overflow: hidden;
        width: 98%;
        margin: 0 auto;
      }
      .bottom-left, .bottom-center, .bottom-right{
        width: 33.33%;
        float:left;
      }
      .bottom-center{
        text-align: center;
        span{
          font-size: 10px;
        }
        img{
          width: 100%;
          max-width: 80%;
        }
      }
      .bottom-left{
          font-size: 1.2em;
      }
      .bottom-left, .bottom-right{
        margin-top: 30px;
      }
      .bottom-right{
        font-size: 0.7em;
        text-align: right;
    }     
}
.searchbox{
    width: 90%;
    margin: 10% auto;
    line-height: 1em;
    color: white;
    font-size: 3em;
    overflow: hidden;
    .searchText{
      width: 80%;
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
      background: white;
      border: 0;  
      float: left;
      padding: 3%;
      color: gray;
      text-transform: uppercase;
    }
    .searchText.active{
      color: $secondary;
    }
    .iconSearch{
      width: 20%;
      text-align: right;
      padding: 2.6%;
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
      background-color: white;
      float: right;
      color: #1a3a87;
    }
  }
.logo{
    width: 70%;
    padding: 5% 0;
    font-size: 46px;
    margin: 0 auto;
    img{
        width: 80%;
    }
    a{
        padding: 6px 0 !important;
        color: white;
        text-transform: uppercase;
        padding: 6px;
        line-height: 42px;
        text-align: center;
        display: block;
        // font-family: 'Bangers', cursive;        
        font-weight: bold;
    }
}

.noContent{
    font-size: 30px;
    margin-top: 60px;
    padding: 10px;
    text-align: center;
}
.content-observer{
    float: left;
    clear: both;
}
.icons > *{
    width: 30px;
    margin: 0 5px;
    color: white;
}

.results{
  background-color: $principal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding-bottom: 60px;
  height: 100vh;
  overflow: scroll;
}

#keyboard {
    font: 71%/1.5 Verdana, Sans-Serif;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    width: 100%;
    max-width: 90%;
    padding-left: 5px;
    margin: 30px auto;
}
#keyboard li {
    float: left;
    font-size: 2.0em;
    width: 8%;
    height: 2.0em;
    line-height: 1.6em;
    margin: 1% 1%;
    padding: 1%;
    text-align: center;
    background: #12a6de;
    color: white;
    // border: 1px solid #f9f9f9;
    -moz-border-radius: 15%;
    -webkit-border-radius: 15%;
}

#keyboard .capslock, #keyboard .return {
    width: 13%;
}
#keyboard .numbers {
    width: 20%;
    -moz-border-radius: 10%;
    -webkit-border-radius: 10%;
}
#keyboard .space {
    width: 52%;
    -moz-border-radius: 2%;
    -webkit-border-radius: 2%;
}
#keyboard .search {
    width: 22%;
    -moz-border-radius: 10%;
    -webkit-border-radius: 10%;
}
#keyboard .numbers, #keyboard .search {
    font-size: 2em;
    line-height: 1.2em;
    height: 1.9em;
}
.lastitem {
    margin-right: 0;
}
.uppercase {
    text-transform: uppercase;
}
.on {
    display: none;
}
#keyboard li:hover {
    position: relative;
    top: 1px;
    left: 1px;
    // border-color: #e5e5e5;
    cursor: pointer;
}
.position-bottom, .position-top{
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    max-height: 450px;
    .ads{
      width: 100%;
      background: #2f2f2f;
      margin: 0 auto;
      float: none ;
      img{
        width: 100%;
      }
      
    }
  }
  .position-bottom{
    bottom: 0;
  }
  .position-top{
    top: 0;
  }
  // results

  .second-header{
    padding: 520px 0 150px;
    background-color: white;
    text-align: center;
    img{
      width: 50%;
      margin: 0 auto;
    }
  }
  
  .list-result{
    width: 90%;
    margin: 20px auto;
  }
  .arrow-left, .arrow-right{
    font-weight: bold;
    font-size: 22px;
    padding: 10px;
  }
  .arrow-left{
    float: left;
    width: 50%;
    text-align: right;
    img{
      width: 30%;
    }
  }
  .arrow-right{
    float: right;
    width: 50%;
    text-align: left;
    color: $secondary;
    img{
      width: 30%;
    }
  }
  
  .list{
    color: white;
  
    .floor{
      background-color: #12a6de;
      padding: 6px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .name, .description, .office{
      width: 90%;
      margin: 0 auto;
    }
  }
  .extra{
    margin-top: -62px;
    border: 1px solid $secondary;
    border-radius: 125px;
    background-color: white;
  }
  .btn-custom{
    margin: 20px auto;
    display: block;
    font-size: 1.6em;
  }
  .navigation{
    display: inline-block;
    overflow: hidden;
    width: 100%;
  }
  .back{
    width: 96%;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    // height: 100vh;
    color: white;
    font-weight: bold;
    line-height: 40px;
    display: block;
    .btn{
        width: 40%;
    }
  }
  .searchbox-desktop{
    display: none;
  }
  .each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.images-wrap{
  float: left;
}