* {
    margin: 0;
    padding: 0;
}
// body {
// }
#container {
    margin: 100px auto;
    width: 688px;
    }
#write {
    margin: 0 0 5px;
    padding: 5px;
    width: 671px;
    height: 200px;
    font: 1em/1.5 Verdana, Sans-Serif;
    background: #fff;
    border: 1px solid #f9f9f9;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}
#keyboard {
    font: 71%/1.5 Verdana, Sans-Serif;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    width: 355px;
    padding-left: 5px;
    margin: 30px auto;
}
#keyboard li {
    float: left;
    margin: 0 5px 5px 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #12a6de;
    color: white;
    // border: 1px solid #f9f9f9;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

#keyboard .capslock, #keyboard .return, #keyboard .numbers {
    width: 47px;
}
#keyboard .space {
    width: 206px;
}
#keyboard .search {
    width: 81px;
}
.lastitem {
    margin-right: 0;
}
.uppercase {
    text-transform: uppercase;
}
.on {
    display: none;
}
#keyboard li:hover {
    position: relative;
    top: 1px;
    left: 1px;
    // border-color: #e5e5e5;
    cursor: pointer;
}