.header {
    // background-color: #282c34;
    background-color: white;
    padding: 30px 20px;
    overflow: hidden;
    // position: fixed;
    width: 100%;
    left: 0;
    top: 0;    
    z-index: 10;
  
    .search, .upload, .register{
      float: right;
      margin-right: 6px;
    }
    .profile{
      float: left;
      a{
          color: white;
          padding: 0 10px;
          font-size: 18px;
          line-height: 30px;
          // font-family: 'Poppins', sans-serif;
      }
    }
    .inner{
      padding: 10px 0;    
    }
    .menuButton, .upload{
      padding: 0 10px 0 0;
      display: block;
      font-size: 30px;
      line-height: 30px;
      top: 6px;
      right: 6px;
      position: absolute;
      z-index: 5;
    }
  }
  a.register{
    display: block;
    padding: 3px 10px;
    border-radius: 4px;
    text-align: center;
    margin-left: 6px;
  }
  .posts{
    width: 100%;
    margin: 0 auto;
    min-height: 100%;
    .loading{
      text-align: center;
    }
  
    #newComment, .comment-list{
      color: white;
      border-bottom: 1px solid #3c3c3c;
      margin-bottom: 6px;
      padding-bottom: 6px;
      
      .author, .text, .created{
        clear: both;
        display: block;
      }
      .author{
        font-weight: bold;
      }
      .text{
        font-size: 1rem;
      }
      .created{
        color: #635f5f;
        font-size: 12px;
        text-transform: uppercase
      }
    }   
  }
  .ads{
    width: 300px;
    float: right;
  }
  // ads
  .ads{
    .ads{
      margin-top: 6px;
    }
  }
  .box{
    width: 300px;
    height: 250px;
    display: block;
    background-color: #000;
  }
  .halfpage{
    width: 300px;
    height: 600px;
    background: #000;
  }
  
  // posts
  .post {
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    // border-bottom: 1px solid #3f3f3f;
    background-color: #3f3f3f;
    border-radius: 6px;
    padding: 10px;
  
    .action{
      overflow: hidden;
    }
    .post-left{
      width: 20%;
      height: 120px;
      text-align: center;
      line-height: 120px;
      font-size: 60px;
      background: #2c2c2c;
      border-radius: 100%;
      float: left;
      margin-right: 1%;
    }
    .post-right{
      float: right;
      width: 79%;
    }    
    img{
      width: 100%;
    }
    h2{
      color: #ccc;
      text-align: left;
      margin: 10px 0;
      line-height: 50px;
    }
    p{
      margin: 4px;
    }
    #newComment, .comment{
      color: white;
      border-bottom: 1px solid #3c3c3c;
      margin-bottom: 6px;
      padding-bottom: 6px;
      
      .author, .text, .created{
        clear: both;
        display: block;
      }
      .author{
        font-weight: bold;
      }
      .text{
        font-size: 1rem;
      }
      .created{
        color: #c2c2c2;
      }
    }  
  }
  .content{
    .profile{
      padding: 20px;
      .item{
          padding: 10px;
          border: 1px solid #2b2b2b;
          margin-bottom: 10px;
          border-radius: 6px;
      }
    }  
  }
  form{
    input[type=checkbox]{
      margin-right: 6px;
    }
  }
  a.login{
    display: block;
    padding: 6px;
  }
  
  .form-comment{
    .item{
      clear: both;
      display: block;
      width: 100%;
    }
    textarea{
      width: 80%;
      margin: 0 auto;
      display: inline-block;
    }
  }
  // signup
  
  .signup{
    color:#e0e0e0;
    padding: 30px;
    background: #222;
    margin-top: 30px;
    border-radius: 6px;
  }
  
  
  // footer
  
  .footer{
    text-align: center;
    font-size: 14px;
  }
  
  //upload
  #uploadForm{
    background:rgba($color: #222, $alpha: 0.6);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 5;
    form{
      overflow: hidden;
      widows: 100%;
      clear: both;
    }
  }
  .btn-active{
    background-color: blue;
    color: white;
  }
  
  .close-upload{
    float: right;
    clear: both;
  }
  .form-control-lg{
    textarea{
      width: 100% !important;
    }
  }
.content{
    overflow: hidden;
    background-size: 100%;

    .left{
        width: 20%;
        top: 0;
        left: -100%;
        height: 100vh;
        position: fixed;
        margin-right: 1%;
        background-color: white;
        .left-menu {
            padding-top: 90px;
            ul{
                margin: 0;
                padding: 0;

                li{
                    list-style: none;
                    transition: all 0.3s ease-in-out;  
                    // padding: 10px 20px;
                    a{
                        display: block;
                        padding: 20px;
                        font-size: 20px;
                        color: unset;
                    }
                }
                // li:hover{
                //     a{
                //         color: #000
                //     }
                // }
                li:nth-child(1):hover{
                    a:after{
                        background-color: rgba(157,53,80, 1);            
                    }
                }
                li:nth-child(2):hover, li:nth-child(8):hover{
                    a:after{
                        background-color: rgba(218,82,135, 1);
                    }
                }
                li:nth-child(3):hover, li:nth-child(9):hover{
                    a:after{
                        background-color: rgba(42   ,27,60, 1);
                    }
                }
                li:nth-child(4):hover, li:nth-child(10):hover{
                    a:after{
                        background-color: rgba(69,57,141, 1);
                    }
                }         
                li:nth-child(5):hover, li:nth-child(11):hover{
                    a:after{
                        background-color: rgba(130,101,167, 1);            
                    }
                }     
                li:nth-child(6):hover, li:nth-child(12):hover{
                    a:after{
                        background-color: rgba(84,129,232, 1);            
                    }
                }             
                li:nth-child(7):hover, li:nth-child(13):hover{
                    a:after{
                        background-color: rgba(164,179,182, 1);            
                    }
                }                           
            }
        }
    }
    .active{
        left: 0;
        z-index: 3;        
    }
    .center{
        width: 100%;
        .App{
            padding-bottom: 10px;
        }
    }
}

.logo{
    width: 250px;
    font-size: 46px;
    line-height: 30px;
    margin: 0 auto;
    img{
        width: 100%;
    }
    a{
        padding: 6px 0 !important;
        color: white;
        text-transform: uppercase;
        padding: 6px;
        line-height: 42px;
        text-align: center;
        display: block;
        // font-family: 'Bangers', cursive;        
        font-weight: bold;
    }
}

.noContent{
    font-size: 30px;
    margin-top: 60px;
    padding: 10px;
    text-align: center;
}
.content-observer{
    float: left;
    clear: both;
}
.icons > *{
    width: 30px;
    margin: 0 5px;
}
.navigation{
  display: inline-block;
  overflow: hidden;
  width: 100%;
}
.back{
  width: 96%;
  margin: 0 auto;
  overflow: hidden;  
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // z-index: 20;
  // height: 100vh;
  color: white;
  font-weight: bold;
  line-height: 40px;
  display: block;
}

.searchbox{
    display: none;
  width: 90%;
  margin: 40px auto 0;
  line-height: 50px;
  color: white;
  font-size: 40px;
  overflow: hidden;
  .searchText{
    width: 80%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background: white;
    border: 0;  
    float: left;
    padding: 3%;
    color: gray;
    text-transform: uppercase;
  }
  .searchText.active{
    color: $secondary;
  }
  .iconSearch{
    width: 20%;
    text-align: right;
    padding: 3%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: white;
    float: right;
    color: #1a3a87;
  }
}
.buscador{
  h2{
    background-color: #12a6de;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    text-align: center;
    width: 94%;
    margin: -60px auto 0;
    z-index: 2;
    position: relative;
    padding: 6px;
    max-width: 500px;
  }
  .keyboard{
    border-top: 35px solid white;
    background-color: #1a3a87;
    display: block;
    overflow: visible;
    padding: 40px 0 60px;
    z-index: 1;
  }
  h3{
    text-align: center;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 50px;
  }
  .bottom{
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 60px;
  }
  .bottom-left, .bottom-center, .bottom-right{
    width: 33.33%;
    float:left;
  }
  .bottom-center{
    text-align: center;
    span{
      font-size: 10px;
    }
    img{
      width: 100%;
      max-width: 80px;
    }
  }
  .bottom-left, .bottom-right{
    margin-top: 30px;
  }
  .bottom-right{
    font-size: 12px;
    text-align: right;
  }
}
.position-bottom, .position-top{
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  .ads{
    width: 320px;
    line-height: 50px;
    background: #2f2f2f;
    margin: 0 auto;
    float: none ;
    img{
      width: 100%;
    }
  }
}
.position-bottom{
  bottom: 0;
}
.position-top{
  top: 0;
}
.results{
  background-color: $principal;
//   position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding-bottom: 220px;
}
.second-header{
  padding: 70px 0 60px;
  background-color: white;
  text-align: center;
  display: none;
  img{
    width: 50%;
    margin: 0 auto;
  }
}

.list-result{
  width: 100%;
  margin: 20px auto;
  max-width: 500px;
}
.arrow-left, .arrow-right{
  font-weight: bold;
  font-size: 22px;
  padding: 10px;
}
.arrow-left{
  float: left;
  width: 50%;
  text-align: right;
  img{
    width: 10%;
  }
}
.arrow-right{
  float: right;
  width: 50%;
  text-align: left;
  color: $secondary;
  img{
    width: 10%;
  }
}

.list{
  color: white;

  .floor{
    background-color: #12a6de;
    padding: 6px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .name, .description, .office{
    width: 90%;
    margin: 0 auto;
  }
}
.extra{
  margin-top: -22px;
  border: 1px solid $secondary;
  border-radius: 25px;
  background-color: white;
}
.btn-custom{
  margin: 20px auto;
  display: block;
  font-size: 30px;
}
// Keyboard

#container {
    margin: 100px auto;
    width: 688px;
    }
#write {
    margin: 0 0 5px;
    padding: 5px;
    width: 671px;
    height: 200px;
    font: 1em/1.5 Verdana, Sans-Serif;
    background: #fff;
    border: 1px solid #f9f9f9;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}
#keyboard {
    font: 71%/1.5 Verdana, Sans-Serif;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    width: 100%;
    max-width: 355px;
    padding-left: 5px;
    margin: 30px auto;
    display: none;
}
#keyboard li {
    float: left;
    margin: 0 5px 5px 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #12a6de;
    color: white;
    // border: 1px solid #f9f9f9;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

#keyboard .capslock, #keyboard .return {
    width: 47px;
}
#keyboard .numbers {
    width: 60px;
}
#keyboard .space {
    width: 193px;
}
#keyboard .search {
    width: 81px;
}
.lastitem {
    margin-right: 0;
}
.uppercase {
    text-transform: uppercase;
}
.on {
    display: none;
}
#keyboard li:hover {
    position: relative;
    top: 1px;
    left: 1px;
    // border-color: #e5e5e5;
    cursor: pointer;
}
.searchbox-desktop{
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    overflow: hidden;

    input[type=text]{
        background: none;
        border: none;
        border-bottom: 1px solid black;
        margin: 0 auto;
        max-width: 400px;
        text-transform: uppercase;
        font-size: 36px;
        color: white;
        width: 80%;
        float: left;
    }
    .iconSearchDesktop{
        width: 20%;
        float: left;
        color: white;
        font-size: 36px;
    }
    input:focus{
        outline: none;
    }    
}
.position-top{
    // display: none;
}